<template>
    <v-navigation-drawer
        v-model="drawer"
        floating
        app
        clipped
        hide-overlay
        stateless
      >
        <v-list
          dense
          rounded
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link"
            :style="item.child === null ? {textDecoration:'none', textAlign:'left'} : {display:'none'}"
          >
            <v-list-item-icon v-if="show(item)">
              <template v-if="item.title === 'Reconciliation' && countRecon > 0">
                <v-badge
                  color="error"
                  :content="countRecon"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
              </template>
              <template v-else-if="item.title === 'Withdrawal Submission' && countWithdrawal > 0">
                <v-badge
                  color="error"
                  :content="countWithdrawal"
                >
                  <v-icon>{{ item.icon }}</v-icon>
                </v-badge>
              </template>
              <v-icon v-else>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content v-if="show(item)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            :value="true"
            v-for="item in items"
            :key="item.icon"
            :prepend-icon="item.icon"
            :style="item.child !== null ? '' : {display:'none'}"
          >
          <template v-slot:activator>
            <v-list-item-title :style="{textDecoration:'none', textAlign:'left'}">{{item.title}}</v-list-item-title>
          </template>
          <v-list-item
            v-for="child in item.childs"
            :key="child.title"
            link
            :to="child.link"
            :style="{textDecoration:'none'}"
          >
            <v-list-item-title>{{child.title}}</v-list-item-title>

            <v-list-item-icon>
              <v-icon>{{child.icon}}</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        </v-list>
        <template v-slot:append>
          <div class="pa-2">
            <!-- <v-btn block color="primary">
              Edit Profil
            </v-btn> -->
            <v-btn @click="$root.logout" block class="mt-2" color="error">
              Keluar
            </v-btn>
          </div>
        </template>
    </v-navigation-drawer>
</template>
<script>
import listmenu from '@/lib/json/list-menu-admin.json'

export default {
    name: 'NavigationDrawer',
    props: ['drawer','countRecon', 'countWithdrawal'],
    data() {
        return {
            items: listmenu.data,
        }
    },
    methods: {
      show(menu) {
        if(!menu.show.mobileDisplay) return true
      }
    },
    updated() {
        this.$emit('clicked', this.drawer)
    },
   
}
</script>