import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

Vue.use(VueRouter)

const routes = [{
        path: '/login',
        name: 'Login',
        component: () =>
            import ('../views/Login.vue'),
        meta: { guest: true }
    },
    {
        path: '/',
        name: 'Home',
        meta: { requiresAuth: true },
        component: () =>
            import ('../views/Home.vue'),
    },
    {
        path: '/reconciliation',
        name: 'Reconciliation',
        component: () =>
            import ('../views/Reconciliation.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/savings',
        name: 'Manage-Savings',
        component: () =>
            import ('../views/settings/Savings.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/members',
        name: 'Manage-Members',
        component: () =>
            import ('../views/settings/Members.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/members/topup',
        name: 'Manage-Topup',
        component: () =>
            import ('../views/settings/Topup.vue'),
        props: route => ({ memberId: route.query.memberId, memberName: route.query.memberName }),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/bulk',
        name: 'Bulk Insert',
        component: () =>
            import ('../views/settings/Bulk.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/account-submission',
        name: 'Account Submission',
        component: () =>
            import ('../views/settings/AccountSubmission.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/withdrawal-submission',
        name: 'Withdrawal Submission',
        component: () =>
            import ('../views/settings/WithdrawalSubmission.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/notification',
        name: 'Manage-Notif',
        component: () =>
            import ('../views/settings/Notification.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/manage/device',
        name: 'Device-Info',
        component: () =>
            import ('../views/settings/DeviceInfo.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/developer/managed/deploy',
        name: 'Managed',
        component: () =>
            import ('../views/developer/Deploy.vue'),
        meta: { guest: true }
    },
    {
        path: '/:catchAll(.*)',
        component: () =>
            import ('../views/NotFound.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next('/')
            return
        }
        next();
    } else {
        next();
    }
});

export default router