<template>
    <!-- nav for admin -->
    <v-card
        flat
        tile>
        <v-app-bar clipped-left flat app color="white" v-if="role == $root.role && !showBottomNav">
          <v-app-bar-nav-icon @click.stop="isClicked"></v-app-bar-nav-icon>

          <v-toolbar-title><img alt="Bufu logo" src="../../assets/bufu-logo-admin.png" width="250"></v-toolbar-title>

          <v-spacer />
          <span :style="{marginRight:'20px'}">{{$store.getters.dataUser !== null ? $store.getters.dataUser.name : ''}}</span>
          <v-avatar color="primary" size="40">
            <span class="white--text caption">{{$root.firstChart($store.getters.dataUser !== null ? $store.getters.dataUser.name : '')}}</span>
          </v-avatar>
        </v-app-bar>

        <v-app-bar clipped-left flat app color="white" v-if="showBottomNav">
          <v-spacer />
          <v-toolbar-title><img alt="Bufu logo" src="../../assets/bufu-logo-load.png" width="150"></v-toolbar-title>
          <v-spacer />
        </v-app-bar>
      </v-card>
</template>
<script>
export default {
    name: 'Appbar',
    props: ['drawer', 'showBottomNav', 'role'],
    components: {
    },
    data() {
      return {
        offset: true,
        clicked: this.drawer,
        data: {
          avatar: null
        }
      }
    },
    methods: {
      isClicked() {
        this.clicked = !this.drawer
        this.$emit('clicked', this.clicked)
      },
      getAvatar(){
        
      }
    },
    mounted(){
      this.getAvatar()
    },
    updated(){
      this.getAvatar()
    }
}
</script>
<style lang="css" scoped>
</style>