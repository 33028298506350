<template>
    <div>
        <Appbar :drawer = drawer :showBottomNav = showBottomNav @clicked = "isClicked" :role = role />
        <NavigationDrawer :drawer = drawer :countRecon=countRecon :countWithdrawal=countWithdrawal @clicked = "isClicked" />
    </div>
</template>
<script>
import Appbar from './navbar/Appbar.vue'
import NavigationDrawer from './navbar/NavigationDrawer.vue'

export default {
    props: ['role','drawer','showBottomNav','countRecon','countWithdrawal'],
    components: {
        Appbar,
        NavigationDrawer,
    },
    methods: {
        isClicked(drawer) {
            this.$emit('clicked', drawer)
        },  
    },
}
</script>