function lowercaseFormat(value) {
    return value.toLowerCase()
}

function isEmpty(value) {
    var valid = false
    if (value == null) {
        valid = true
    } else if (value == '') {
        valid = true
    } else {
        valid = false
    }
    return valid
}

function validateEmail(value) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (value.match(mailformat)) {
        return true
    } else {
        return false
    }
}

function currencyFormat(value) {
    let val = (value / 1).toFixed(2).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

function contactWA(noContact, text) {
    return "https://wa.me/" + noContact + "?text=" + encodeURIComponent(text)
}

function contactEmail(noEmail, subject, body) {
    return "mailto: " + noEmail + "?subject=" + subject + "&body=" + body
}

function contactPhone(noPhone) {
    return "tel:" + noPhone
}

function formatDate(date) {
    if (date !== undefined && date !== "") {
        var myDate = new Date(date);
        var month = [
            "Januari",
            "February",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Agustus",
            "September",
            "Oktober",
            "November",
            "Desember",
        ][myDate.getMonth()];
        var str = myDate.getDate() + " " + month + " " + myDate.getFullYear();
        return str;
    }
    return "";
}

function capitalFirstChart(value) {
    value = value.toLowerCase()
    return value.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    })
}

function firstChart(value) {
    var newValue = ''
    newValue = value.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase()
    })
    return newValue.replace(' ', '').replace(' ', '')
}

function setEmptyStringIfNull(value) {
    if (value == null) return ''
    else return value
}

function setNolIfNull(value) {
    if (value == null) return 0
    else return value
}

export {
    capitalFirstChart,
    firstChart,
    lowercaseFormat,
    isEmpty,
    validateEmail,
    currencyFormat,
    formatDate,
    contactWA,
    contactEmail,
    contactPhone,
    setEmptyStringIfNull,
    setNolIfNull
}