<template>
  <v-app>
    <div id="app" :style="{ backgroundImage: createBackgroundString }">
        <Navbar v-show="isAuthenticated" :role = role :drawer=drawer :showBottomNav=showBottomNav :countRecon=countRecon :countWithdrawal=countWithdrawal @clicked = "onUpdate" />
        <OverlayBreakpoint v-if="showBottomNav" />
        <router-view/>
    </div>
  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import OverlayBreakpoint from '@/components/OverlayBreakpoint.vue'

export default {
  name: 'App',
components:{
    Navbar,
    OverlayBreakpoint
  },
  data() {
    return {
      isAuthenticated: this.$store.getters.isAuthenticated,
      role: this.$store.getters.role,
      drawer: null,
      showBottomNav: false,
      countRecon: 0,
      countWithdrawal: 0,

      angle: '180',
      color1: '#e7e4f1',
      color2: '#fff'
    }
  },
  computed: {
    createBackgroundString() {
      return `linear-gradient(${this.angle}deg, ${this.color1}, ${this.color2})`;
    }
  },
  methods: {
    menuDrawerRoleCheck() {
      if(this.role == this.$root.role) this.drawer = true
      else this.drawer = false
    },
    breakpoint() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': 
          this.drawer = false
          this.showBottomNav = true
          break
          case 'sm': 
          this.drawer = false
          this.showBottomNav = true
          break
          case 'md': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
          case 'lg': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
          case 'xl': 
          this.menuDrawerRoleCheck()
          this.showBottomNav = false
          break
        }
    },
    onUpdate(value) {
      this.drawer = value
    },
    async getCountRecon() {
      var id, status = 'Pending'
      this.listPayment = []
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.status,
      }).then((res) => {
          id = res.data.paymentStatus.find(x => x.statusName === status).idStatus
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
  
      await this.$root.api.sentAPI(
      {
          method: 'get',
          api: this.$root.api.get.listPayment + '?idStatus='+id
      }).then((res) => {
          this.countRecon = res.data.length
      })
      .catch((err) => {
          this.$root.isError = true
          if(!navigator.onLine) this.$root.isError = err.message
      })
    },
    async getCountWithdrawal() {
        var api = this.$root.api.get.cashWithdrawal+'?statusCode=7'
        await this.$root.api.sentAPI(
        {
            method: 'get',
            api: api,
        }).then((res) => {
          this.countWithdrawal = res.data.count
            
        })
        .catch((err) => {
            this.$root.isError = true
            if(!navigator.onLine) this.$root.isError = err.message
        })
    },
    getDataTransc(){
      this.getCountRecon()
      this.getCountWithdrawal()
    }
  },
  created() {
      this.breakpoint()
  },
  updated() {
    this.isAuthenticated = this.$store.getters.isAuthenticated
    this.role = this.$store.getters.role
    if(!this.isAuthenticated) this.breakpoint()
    else this.getDataTransc()
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
