import axios from 'axios'
import store from '../../store'
import router from '../../router'

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

const get = {
    simpanan: 'bufu/simpanan/get',
    listPayment: 'bufu/pembayaran-list/get',
    status: 'bufu/status/get',
    member: 'bufu/member/get',
    exportExcel: 'bufu/member/export-excel',
    download: 'bufu/file/download',
    accountSubmission: 'bufu/account/list',
    cashWithdrawal: 'bufu/trx/cashWithdrawal/list',

    notification: 'notification/get',
    device: 'device-info/get',

    dashboard: {
        chart: 'bufu/dashboard/chart',
        savingCalculate: 'bufu/dashboard/saving/calculate'
    }
}

const post = {
    login: 'bufu/authenticate',
    simpanan: 'bufu/simpanan',
    reconAuto: 'bufu/recon/auto',
    reconManual: 'bufu/recon/manual',
    topup: 'bufu/transaksi/input',
    bulkMember: 'bufu/document/member/upload',
    bulkDeposite: 'bufu/document/deposit/upload',
    accountSubmission: 'bufu/account/approval',
    cashWithdrawal: 'bufu/trx/cashWithdrawal/approval',

    notification: 'notification/put',
}

const update = {
    memberEmail: 'bufu/member/update'
}

const del = {
    rekon: 'bufu/transaksi/delete',
    member: 'bufu/member/delete',
    notification: 'notification/delete'
}

function sentAPI(param) {
    if (store.getters.accessToken !== null) axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.accessToken
    else axios.defaults.headers.common['Authorization'] = ''

    return new Promise(function(resolve, reject) {
        let response = null
        let error = null

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
        axios({
            url: param.api,
            method: param.method,
            data: param.form,
            headers: headers,
        }).then(res => {
            response = res.data
            resolve(response)
        }).catch((err) => {
            error = err
            if (error.message === 'Request failed with status code 401') {
                store.dispatch('LogOut')
                router.push('/login').catch(() => {})
            } else reject(error)
        })
    })
}

const api = {
    axios,
    sentAPI,
    get,
    post,
    update,
    del
}

export default api