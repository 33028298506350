import api from '../../lib/util/api.js'
import message from '../../lib/util/message.js'

const state = {
    user: null,
    role: null,
    token: null,
    dataUser: {
        name: null,
        tanzeem: null,
        noAims: null,
        branch: null,
        statusMember: null,
        changePass: null
    },
    avatar: false
}
const getters = {
    isAuthenticated: state => !!state.token,
    accessToken: state => state.token,
    stateUser: state => state.user,
    role: state => state.role,
    dataUser: state => state.dataUser,
    getAvatar: state => state.avatar
}
const actions = {
    async LogIn({commit}, User) {

        return await api.sentAPI({
            method: 'post',
            api: api.post.login,
            form: {
                email: User.get('username'),
                password: User.get('password')
            }
        }).then(res => {
            if(res.data.authenticate.accessToken !== null) {
                commit('setDataUser', {
                    name: res.data.member.fullName,
                    noAims: res.data.member.noAims,
                    statusMember: res.data.member.memberStatus,
                    changePass: res.data.member.changedPassword
                })
                commit('setRole', res.data.member.role)
                commit('setToken', res.data.authenticate.accessToken)
                return {
                    status: 200,
                    message: res.data.authenticate.message
                }
            }else{
                return {
                    status: 404,
                    message: res.data.authenticate.message
                }
            }
        }).catch(err => {
            console.log(err)
            return {
                status: 500,
                message: message.result.errMessageInternal
            }
        })
    },

    async LogOut({commit}){
        // await api.sentAPI({
        //     method: 'post',
        //     api: api.post.logout,
        // }).then(() => {
        // }).catch(()=>{})
        commit('LogOut', null)
    },
}
const mutations = {
    setUser(state, username){
        state.user = username
    },
    setRole(state, role){
        state.role = role
    },
    LogOut(state){
        state.user = null
        state.role = null
        state.token = null
        state.dataUser = null
        state.avatar = false
    },
    setToken(state, token){
        state.token = token
    },
    setDataUser(state, data){
        state.dataUser = data
    },
    setStatusChangePass(state, data){
        state.dataUser.changePass = data
    },
    getAvatar(state, data){
        state.avatar = data
    },
}
  
export default {
  state,
  getters,
  actions,
  mutations
}