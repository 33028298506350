import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import message from './lib/util/message.js'
import { firstChart, capitalFirstChart, currencyFormat, lowercaseFormat, setEmptyStringIfNull, setNolIfNull, isEmpty } from './lib/util/utilities.js'
import api from './lib/util/api.js'
import terbilang from 'angka-menjadi-terbilang'

Vue.config.productionTip = false

new Vue({
    data: { overlay: true, isError: false, message, role: 'ROLE_ADMIN', api },
    store,
    router,
    vuetify,
    methods: {
        terbilang,
        firstChart,
        capitalFirstChart,
        currencyFormat,
        lowercaseFormat,
        setEmptyStringIfNull,
        setNolIfNull,
        isEmpty,
        async logout() {
            await this.$store.dispatch('LogOut')
            this.$router.push('/login')
        }
    },
    render: h => h(App)
}).$mount('#app')